
body#module-prestablog-blog {
  #prestablogfront {
    border: 0;
    font-family: $font-barlow;
    letter-spacing: 0.15px;
  }

  .blog_nav_cat_title {
    margin-top: 20px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
    position: relative;
    text-transform: uppercase;

    &:before {
      content: '';
      display: block;
      height: 3px;
      width: 50px;
      background: $epi-color-yellow;
      position: absolute;
      bottom: -3px;
      left: 0;
    }
  }

  .article_header {
    margin: 30px auto;
    border-bottom: 1px #dedede dotted;
    padding-bottom: 15px;

    #prestablog_article_title {
      text-align: left;
      font-family: $font-oswald;
      letter-spacing: 0.32px;
      color: $epi-color-blue;
      margin: 10px auto 20px auto;
    }

    time.date, .info_blog {
      text-align: unset;
      margin: 0;
      line-height: 1.2;

      span {
        margin-left: 0;
        padding-left: 0;
      }

      &:before {
        display: none;
      }
    }

  }
}

.block-categories {
  padding: 0;
}

ul.prestablogtree {
  li span {
    font-weight: 300;
    transition: ease-in-out;
  }
}


@media (max-width: 640px) {
  #prestablogfont {
    img.news {
      float: none;
      margin: 20px auto;
      display: block;
    }
  }
}

@media (min-width: 641px) {
  body#module-prestablog-blog #left-column {
    padding-left: 0;

  }


}