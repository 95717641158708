#products,
#pagenotfound {
  #main {
    .page-header {
      margin: 2rem 0 3rem;
    }

    .page-content {
      margin-bottom: 10rem;
    }
  }


}

.page-404-container {
  max-width: 940px;
  margin: 50px auto;

  .search-widget {
    width: 100%;
    margin-top: 35px;

    form {
      border: 3px solid $epi-color-yellow;

      input[type="text"] {
        width: 100%;
        border: 0;
      }

      button[type=submit] {
        color: black;
        height: 100%;
        top: 0;
        background: $epi-color-yellow;
        right: 0;

        i {
          font-size: 30px;
        }
      }
    }
  }
}

.page-404-code-number {
  font-size: 115px;
  font-weight: 900;
  color: $epi-color-blue;
  padding: 20px 40px 20px 20px;
  position: relative;
  display: block;

}

.page-404-innercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-4004-content-right {
  position: relative;

  h4 {
    font-size: 15px;
    font-weight: 500;
    margin: 0.5rem 0 1rem;
  }

  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    transform: translateY(-50%);
    left: -25px;
    top: 50%;
    background: $epi-color-blue;

  }

}

@media screen and (max-width: 580px) {

  .page-content {
    padding-left: 15px;
    padding-right: 15px;
    .page-404-innercontainer{
      flex-direction: column;
    }
    #search_widget {
      width: 100% !important;
    }
    .page-4004-content-right{
      margin-top: 40px;
      padding-top: 35px;
      text-align: center;
      &:before{
        width: 220px;
        height: 3px;
        background: $epi-color-blue;
        left: 50%;
        transform: translateX(-50%);
        top:0 ;
      }
    }
  }

}