.epi-promos-wrapper {
  .products-section-title {
    @include section-part-title($epi-color-yellow, $epi-color-blue, white);
    @media screen and (min-width: $dispaly-slider-point) {
      @include section-part-title($epi-color-yellow, $epi-color-blue, $gray-lighter-second);
    }
    text-transform: initial;
  }

  .products {
    padding-top: 60px;
  }
}

.epi-promo-all-container {
  text-align: center;
  > a.all-product-link {
    display: inline-block;
    max-width: 250px;
    margin: auto;
    text-transform: uppercase;
    font-size: 14px;
    color: $epi-color-blue;
    transition: border ease-in-out .4s, background ease-in .45s, color ease-in .3s;
    padding: 7px 10px;
    text-align: center;
    border: 1px solid transparent;

    &:hover{
      border: 1px solid $epi-color-blue;
      background:$epi-color-blue;
      color: white;
    }
  }
}