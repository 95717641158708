.block_newsletter {
  @include search-box;
  font-size: $font-size-sm;
  margin-bottom: 70px;

  p {
    padding-top: $small-space;
  }

  form {
    .input-wrapper {
      overflow: hidden;
    }

    input[type=email] {
      padding: 11px;
      border: 5px solid $epi-color-yellow;
      border-right: transparent;

      &:focus {
        //  border: 3px $brand-primary solid;
        padding: 8px 8px 9px;
        outline: 0;
      }

      width: 100%;
    }

    input {
      height: 55px;
      box-shadow: none;

      &.btn-primary {
        background: transparent;
        font-family: $font-oswald;
        font-weight: 500;
        color: black !important;
        text-transform: uppercase;
        border: 5px solid $epi-color-yellow;
        border-left: transparent;
        opacity: 1;
        font-size: 25px;
        transition: ease-in-out .3s;

        &:hover {
          background: $epi-color-yellow;
        }
      }
    }
  }

  // ===========epi- newsletter ==================
  .newsletter_from_wrapper,
  .block_newsletter_heading {
    max-width: 450px;
    margin: auto;

  }

  .block_newsletter_heading {
    font-family: $font-oswald;
    text-align: center;
    margin-top: 115px;
    margin-bottom: 15px;

    .block_newsletter_title {
      display: block;
      font-weight: 500;
      color: black;
      font-size: 24px;
    }

    .newsletter_subtitle {
      font-family: $font-monsterrat;
      font-size: $epi-font-size-base;
    }

  }
}


/* Epi block reassurance footer*/
.blockreassurance {
  margin-bottom: -70px;
}

.epi-reassurance-footerItem {
  width: 245px;
  height: 245px;
  background: url("../../img/test.png") repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .epi-reassurance-itemInner{
    display: flex;
    flex-direction: column;
    text-align: center;
    background: white;
    height: calc(100% - 25px);
    width: calc(100% - 25px);
    justify-content: center;
  }
  .block-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 30px;
      height: unset !important;
    }
  }

  .block-title {
    font-family: $font-monsterrat;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900 !important;
    padding: 0 20px;
  }

  // cas particulier pour le dernier item qui semble ne pas avoir une image pas de la même taille que les autres
  .item_4 .block-icon img {
    max-width: 65px;
  }
}


.block-contact {
  color: $gray;

  .block-contact-title {
    color: $gray-darker;
  }
}

.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}

.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}

.block-social {
  text-align: right;

  ul {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      height: 2.5rem;
      width: 2.5rem;
      background-color: $gray-light;
      background-repeat: no-repeat;
      display: inline-block;
      margin: 0.125rem;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        height: 2rem;
        width: 2rem;
        margin-right: 1.3rem;
        margin-bottom: 1.3rem;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-primary;
      }

      a {
        display: block;
        height: 100%;
        white-space: nowrap;
        text-indent: 100%;
        overflow: hidden;

        &:hover {
          color: transparent;
        }
      }
    }
  }
}

.facebook {
  background-image: url(../../img/facebook.svg);

  &::before {
    content: "";
    background-image: url(../../img/facebook-blue.svg);
  }

  &.icon-gray {
    background-image: url(../../img/facebook-gray.svg);

    &:hover {
      background-image: url(../../img/facebook-blue.svg);
    }
  }
}

.twitter {
  background-image: url(../../img/twitter.svg);

  &::before {
    content: "";
    background-image: url(../../img/twitter-blue.svg);
  }

  &.icon-gray {
    background-image: url(../../img/twitter-gray.svg);

    &:hover {
      background-image: url(../../img/twitter-blue.svg);
    }
  }
}

.rss {
  background-image: url(../../img/rss.svg);
}

.youtube {
  background-image: url(../../img/youtube.svg);
}

.googleplus {
  background-image: url(../../img/gplus.svg);

  &::before {
    content: "";
    background-image: url(../../img/gplus-blue.svg);
  }

  &.icon-gray {
    background-image: url(../../img/gplus-gray.svg);

    &:hover {
      background-image: url(../../img/gplus-blue.svg);
    }
  }
}

#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-darker;
  }
}

.pinterest {

  background-image: url(../../img/pinterest.svg);

  &::before {
    content: "";
    background-image: url(../../img/pinterest-blue.svg);
  }

  &.icon-gray {
    background-image: url(../../img/pinterest-gray.svg);

    &:hover {
      background-image: url(../../img/pinterest-blue.svg);
    }
  }
}

.vimeo {
  background-image: url(../../img/vimeo.svg);
}

.instagram {
  background-image: url(../../img/instagram.svg);
}

.footer-container {
  margin-top: $medium-space;
  padding-top: 130px;
  overflow: hidden;
  background: $gray-light;

  li {
    margin-bottom: 0.3125rem;
  }

  li a {
    color: $gray;
    cursor: pointer;
    font-size: $font-size-sm;

    &:hover {
      color: $epi-color-blue;
    }
  }

  p.h3,
  p.h3 > a,
  p.h4 {
    text-transform: initial !important;
    font-size: 18px;
    color: #535353;
    font-family: $font-monsterrat;
    font-weight: 700;
  }

  .footer-maccaron-payment {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;

    > span {
      text-transform: uppercase;
      border: 5px solid black;
      font-family: $font-oswald;
      color: black;
      text-align: center;
      padding: 10px;
      max-width: 163px;
    }

  }

  .text-sm-center a {
    color: #999999;
    transition: color ease-in-out .3s;

    &:hover {
      color: $epi-color-blue;
    }
  }
}

.links {
  .collapse {
    display: inherit;
  }
}


@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $gray-lighter;
  }
  .footer-container {
    box-shadow: none;
    margin-top: 0;

    .wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .links {
      .h3 {
        line-height: 1.5;
        font-size: 1rem;
      }

      ul {
        background-color: $gray-lighter;
        margin-bottom: 0;

        > li {
          padding: 0.625rem;
          border-bottom: 1px solid white;
          font-weight: bold;

          a {
            color: $gray-darker;
          }
        }
      }
    }
  }
  .links {
    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem;
      border-bottom: 1px solid $gray-lighter;
      cursor: pointer;

      .collapse-icons .remove {
        display: none;
      }
    }

    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}

@media screen and (max-width: 580px) {
  .epi-blockreassurance-container{
    > .row{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      > div{
        padding: 0;
      }
    }
  }
  .epi-reassurance-footerItem {
    width: 130px;
    height: 130px;
    .epi-reassurance-itemInner {

      .block-icon {
        float: none;
        clear: both;
        margin: auto;
        height: unset;
        width: unset;

        img {
          width: 35px;
        }
      }

      .block-title {
       font-size: 11px;
        width: 100%;
        line-height:1;
        padding: 0;
        max-width: 100%;
        height: auto;
      }
    }

    &:last-child img{
        width: 45px !important;

    }
  }
}

@media (max-width: 768px) {
  .epi-reassurance-footerItem {
    margin: 20px auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .epi-blockreassurance-container{
    padding: 0;
    .epi-reassurence-block-footer{
      padding: 0;
      .epi-reassurance-footerItem {
        width: 190px;
        height: 190px;

        .block-title{
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  .block-contact {
    padding-left: 1.5rem;
  }
}
