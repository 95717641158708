.contact-rich {
  color: $gray;
  margin-bottom: 2rem;
  word-wrap: break-word;
  h4 {
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 2rem;
  }
  .block {
    height: auto;
    overflow: hidden;
    .icon {
      float: left;
      width: 3.5rem;
      i {
        font-size: 2rem;
      }
    }
    .data {
      color: $gray-darker;
      font-size: $font-size-sm;
      width: auto;
      overflow: hidden;
      &.email{
        padding-top: 0.375rem;
      }
    }
    a[href^="mailto:"] {
      clear: both;
      display: block;
      margin-top: 1rem;
      
      @include media-breakpoint-up(sm) {
        font-size: .815rem;
      }
    }
  }
  .block-technique{
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px #cccccc dotted;
    span{
      font-weight: 600;
    }
    i{
      color: #0a0a0a;
    }
  }
}
.contact-form {
  background: white;
  padding: 1rem;
  color: $gray;
  width: 100%;
  padding-top: 0;
  h3 {
    text-transform: uppercase;
    color: $gray-darker;
  }
}
