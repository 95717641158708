@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/header-nav-top";
@import "partials/_univers";
@import "partials/section-promos";
@import "partials/home_blog";
@import "partials/override_blog";
//@import "partials/_custom-text";

/*** HEADER ***/
#header {
  // background: white;
  position: relative;
  color: $gray;

  .logo {
    max-width: 100%;
  }

  a {
    color: $gray-darker;

    &:hover {
      text-decoration: none;
      color: $brand-primary;
    }
  }

  .menu {
    display: inline-block;
  }

  .top-menu-link {
    margin-left: $medium-space;
  }

}

.popover {
  font-family: inherit;
}
.page-body{
  .card{box-shadow: none;}
  button[name="submitCustomizedData"]{
    background: $epi-color-blue;
    text-transform: initial;
    &:hover{
      background: darken($epi-color-blue,10%);
    }
  }
}

/*** WRAPPER ***/
#wrapper {
  //background: $gray-light;
  > .container{
    max-width: 1200px;
    width: 100%;
  }

  //box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.11);
  padding-top: 1.563rem;

  .banner {
    margin-bottom: 1.5rem;
    display: block;

    img {
      box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .breadcrumb {
    background: transparent;
    padding: 0;

    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        content: "/";
        color: $gray;
        margin: 0.3125rem;
      }

      &:last-child {
        content: "/";
        color: $gray;
        margin: 0;

        &::after {
          content: "";
        }
      }

      a {
        color: $gray-darker;
      }
    }
  }
}

/*** MAIN ***/
#main {
  .page-header {
    margin-bottom: 1.563rem;
  }

  .page-content {
    margin-bottom: 1.563rem;

    h6 {
      margin-bottom: 1.125rem;
    }

    #notifications {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .page-footer {
    margin-bottom: 1.563rem;
  }

}

#notifications {
  ul {
    margin-bottom: 0;
  }
}

/*** FOOTER ***/
#footer {
  // padding-top: 2.5rem;
}

/*** Responsive part ***/
#content-wrapper{
  position: relative;
}
@media (min-width: $dispaly-slider-point) {
  body#index,
  body#category,
  body#product{
    background: $gray-lighter-second;
    //le rond au milieu de la page
    &:before {
      content: '';
      display: block;
      background: white;
      width: 95%;
      max-width: 1780px;
      height: 1450px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -220px
    }
  }
  body#category:before {
    height: 1260px !important;;
  }

}




@include media-breakpoint-down(lg) {
  #header {
    .header-nav {
      max-height: inherit;

      .search-widget {
        float: none;
        width: 15.63rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  #header {
    .logo {
      width: auto;
    }

    .header-top {
      .search-widget {
        min-width: inherit;
      }
    }
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .sub-menu {
    left: 0;
    min-width: 100%;
  }
  #blockcart-modal .product-image {
    width: 100%;
    display: block;
    max-width: 15.63rem;
    margin: 0 auto 0.9375rem;
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .product-name,
  #product-availability {
    margin-top: $small-space;
  }
  #search_filters .facet .facet-label {
    text-align: left;
  }
  .block-category .category-cover {
    position: relative;
    text-align: center;
  }
  .block-category {
    padding-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {
  #wrapper {
    box-shadow: none;
  }
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
    background: $gray-light;

    .header-nav {
      background: white;
      margin-bottom: 0.625rem;
      color: $gray-darker;


      .right-nav {
        flex-direction: column;
      }

      .user-info {
        text-align: left;
        margin-left: 0;

        .logged {
          color: $brand-primary;
        }
      }

      .blockcart {
        margin-left: 0;
        background: inherit;

        &.active {
          margin-left: 0.5rem;
        }

        &.inactive {
          .cart-products-count {
            display: none;
          }
        }
      }
    }

    .header-top {
      background: $gray-light;
      padding-bottom: 0;

      a[data-depth="0"] {
        color: $gray-darker;
      }

      .search-widget {
        width: 100%;
      }
    }

    &.is-open {
      background-color: white;

      .header-top {
        background-color: white;
      }
    }
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .block-contact {
    padding-left: 0.9375rem;
    border: none;
  }
  .menu,
  .dropdown-item {
    padding-left: 0;
  }
  #footer {
    padding-top: 0.5rem;
  }
}

@include media-breakpoint-down(xs) {
  #left-column, #content-wrapper,
  #wrapper > .container{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

input[type=number] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
