.display-home-slider {
  max-width: 1550px;
  margin: auto;
  position: relative;

  @media (min-width: $dispaly-slider-point) {
    &:before,
    &:after {
      content: '';
      width: 80px;
      height: 660px;
      display: block;
      position: absolute;
      top: -5px;
      z-index: 1;
    }
    &:after {
      right: 0;
      background: transparent url("../../img/epi_slider_right_corner.png") no-repeat;
    }
    &:before {
      left: 0;
      background: transparent url("../../img/epi_slider_left_corner.png") no-repeat;
    }
  }

}


.carousel {
  //box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1.5rem;

  .direction {
    z-index: auto;
  }

  .carousel-inner {
    //height: 340px;
  }

  .carousel-item {
    height: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    .caption {
      position: absolute;
      bottom: 230px;
      left: 5px;
      padding: 10px 15px;
      color: white;
      background: rgba(0, 0, 0, 0.3);
      max-width: 500px;

      .caption-description p {
        color: white;
      }
      .display-1{
        font-size: 30px;
        font-weight: 600;
        line-height: 1;
      }
    }

    figure {
      margin: 0;
    }
  }

  .carousel-control {
    opacity: 1;

    .icon-next,
    .icon-prev {
      display: none;
      &::before {
        content: "";
      }

      i {
        font-size: 3.125rem;
        color: $epi-color-blue;
      }

      &:hover {
        i {
          color:darken($epi-color-blue,10%);
        }
      }
    }

    .icon-prev {
      left: -25px;
    }

    .icon-next {
      right: -15px;
    }

    &.left,
    &.right {
      background: none;
    }
  }
  .carousel-indicators li{
    width: 16px;
    height: 16px;
    &:not(.active){
      background: rgba(153, 153, 153, 0.46);
      border: transparent;
    }
  }
}

@include media-breakpoint-down(lg) {
  .carousel {
    .carousel-inner {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 2rem);
        left: 10%;
      }

      .caption {
        .display-1 {
          font-size: 2rem;
        }
      }

      .caption-description {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 1.5rem);
      }

      .caption {
        .display-1 {
          font-size: 16px;
        }
      }
    }

    .carousel-control {
      .icon-prev, .icon-next {
        i {
          font-size: 2rem;
        }
      }
    }
  }
}
