.section-blog {
  background: $epi-color-yellow;

  .home-blog-title {
    text-align: center;
    @include section-part-title(white, $epi-color-blue, $epi-color-yellow);
  }

  .home-blog-container {
    padding: 1px 0 30px 0;
  }
}

.home-blog-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 30px 0;
  justify-content: space-between;
}

.home-blog-item {
  background: white;
  width: calc(50% - 11px);
}

a.blog-item-linkwrapper {
  display: flex;
  height: 100%;

  > div {
    flex-basis: 50%;
  }

}

.home-blog-imgcontainer {
  background-size: cover;
  background-position: center;
}

.home-blog-content {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  font-family: $font-barlow;

  .home-blog-date {
    font-size: 14px;
    color: #898987;
    font-weight: 400;
  }

  .home-blog-item-title {
    font-size: 24px;
    color: #252525;
    font-weight: 600;
  }

  .home-blog-intro {
    flex-grow: 1;
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.16px;
  }

  .epi-readmore-link {
    background: transparent;
    transition: ease-in-out .3s;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    font-family: $font-oswald;
    color: #252525;
    padding-left: 0;


    span {
      transition: ease-in-out .4s;
      //transform: translateX(0);
      display: inline-block;
    }

    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: $epi-color-blue;
      padding-left: 8px;

      span {
        transform: translateX(5px);
      }
    }

  }
}

.epi-blog-all-container{
  text-align: center;
  margin: 20px auto;

  .more-news-link{
    text-transform: uppercase;
    font-size: 14px;
    color: white;
    font-family: $font-oswald;
    font-weight: 500;
    letter-spacing: 0.35px;
    transition: ease-in-out .3s;
    border: 1px solid transparent;
    &:hover{
      border: 1px solid white;
      padding: 10px;
      text-align: center;
    }
  }
}

@media (max-width: 640px) {
  .section-blog .home-blog-title{
    margin-bottom: 35px;
  }
  a.blog-item-linkwrapper {
    flex-direction: column;

    > .home-blog-imgcontainer {
      min-height: 200px !important;
      height: 150px !important;
    }
    .home-blog-content .home-blog-item-title {
      font-size: 20px;
    }
  }

}

@media (max-width: 1024px) {
  .home-blog-container {
    width: 95%;
  }
  .home-blog-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .home-blog-item {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}


@media(min-width: 1300px) {
  .home-blog-container {
    width: 1200px;
    padding: 0;
  }
}