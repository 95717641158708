.block_custom_text {
  //height:850px;
  background: url("../../img/bgsection.jpg") no-repeat center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

#custom-text {
  //  margin-bottom: 1.5rem;
  padding: 100px 30px;
  display: flex;
  @media (min-width: 1800px) {
    max-width: 80%;
  }
  margin: auto;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  //text-align: center;
  h2,
  h3 {
    color: white;
    font-size: 33px;
    font-weight: 700;
    text-align: right;
    border-top: 4px solid $epi-color-yellow;
    border-bottom: 4px solid $epi-color-yellow;
    padding: 30px 0;
    margin: auto 50px;
    line-height: 1.3;
    flex-basis: 70%;
    @media (min-width: 1800px) {
      flex-basis: 30%;
    }

    + div {
      width: 100%;
      @media (min-width: 1800px) {
        max-width: 500px;

      }
    }
  }

  h4 {
    color: $epi-color-yellow;
    font-size: 31px;
    margin-bottom: 20px;
  }

  img {
    max-width: 200px;
  }

  p {
    color: white;
    font-weight: 100;
    font-size: 1.1em;
    line-height: 1.5;

    .dark {
      font-weight: 400;
    }
  }

  ul {
    margin-left: 30px;
    margin-bottom: 25px;
    margin-top: 25px;

    li {
      display: flex;
      align-items: center;
      margin: 8px 0;
    }
  }
}

.page-content.page-cms {
  background: white;
  //padding: 1.25rem;
  text-align: justify;

  .cms-box {
    img {
      max-width: 100%;
    }
  }
}


/*=============
Responsive
================
*/
//max 640px ;
@include media-breakpoint-down(sm) {
  .block_custom_text {
    background-position: 72% 0;
  }


  #custom-text {
    padding: 3.125rem 0;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2,
    h3 {

      font-weight: 700;
      text-align: left;
      padding: 30px 15px;
      margin: 80px auto;
      flex-basis: 100%;
      font-size: 22px;

      + div {
        display: none;
      }
    }
  }
}


@media (min-width: 768px) and (max-width: 1024px) {

  #custom-text {
    padding: 60px 0;
    display: flex;
    max-width: 100%;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    h2,
    h3 {
      text-align: center;
      padding: 30px 0;
      margin: 50px;

      + div {
        max-width: 100%;
        width: 100%;
        padding: 25px;
      }
    }


  }

}

@media (min-width: 1025px) and (max-width: 1300px) {
  #custom-text {
    max-width: 100%;
    padding: 50px 5px;
  }
}


@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}
