$gray-dark: #363A42;
$gray-darker: #232323;
$gray: #7A7A7A;
$gray-light: #F1F1F1;
$gray-light-second: #DBDBDB;
$gray-lighter: #f6f6f6;
$gray-lighter-second: #F7F7F7;
$brand-primary: #2fb5d2;
$brand-secondary: #F1BF10;//#f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$input-border-color: rgba(0, 0, 0, 0.25);
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-second: #F49D6E;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$NotoSansPath: "~notosans-fontface/fonts";
$NotoSansVersion: "1.0.1";

$font-oswald: 'Oswald', sans-serif;
$font-monsterrat:'Montserrat', sans-serif;
$font-barlow:'Barlow', sans-serif;
$epi-color-yellow: #F1BF10;
$epi-color-blue:#011689;

$epi-font-size-base:14px;

$dispaly-slider-point:1500px;