.prduct-univers-title {
  @include section-part-title($epi-color-yellow, $epi-color-blue, #fff);
}

.list-univers {
  display: grid;
  max-width: 1024px;
  margin: auto;
  grid-template-columns: repeat(3, minmax(200px, 310px));
  gap: 30px;
  @media (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(150px, 250px));
    gap: 2px;
  }


  .home-iniver-item {
    position: relative;

    a {
      display: block;
      color: black;
      overflow: hidden;
      position: relative;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 50%;
        width: 100%;
        background: rgba(0, 0, 0, 0.15);
        transition: all ease-in-out .5s;
      }

      &:after {
        top: 0;
      }

      &:before {
        bottom: 0;
      }

      img {
        transition: all ease-in-out .7s;
        transition-delay: 200ms;
      }

      &:hover {
        &:after {
          top: -100%;
        }

        &:before {
          bottom: -100%;
        }

      }
    }

    h3 {
      position: absolute;
      transition: all ease-in-out;
      font-family: $font-oswald;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      bottom: 50%;
      background: white;
      max-width: 90%;
      left: 50%;
      padding: 15px;
      z-index: 3;
      transform: translateX(-50%) translateY(50%);

    }
  }
}

@media screen and (max-width: 640px) {
  .list-univers {
    grid-template-columns: repeat(2, minmax(150px, 250px));
    gap: 2px;
    justify-content: center;
    .home-iniver-item h3 {
      padding: 5px;
      font-size: 10px;
      max-width: 100%;
      width: 85%;
    }
  }
}

@media screen and (min-width: 641px) and (max-width:767px){
  .list-univers{
    gap: 10px;
  }
}