/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
 // @include box-shadow;
  background: white;
  padding: 1rem;
}
@mixin customer-area-box {
  @include customer-area-base-box;
  font-size: $font-size-sm;
  color: $gray;
}
.table-labeled {
  th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
  }
  .label {
    font-weight: 400;
    border-radius: 3px;
    font-size: inherit;
    padding: 0.25rem 0.375rem;
    margin: 0.125rem;
    color: white;
    white-space: nowrap;
  }
}
.page-order {
  .table {
    margin-bottom: 0;
  }
  table {
    th {
      padding: 0.5rem;
    }
    td {
      padding: 0.5rem;
    }
    thead {
      th {
        text-align: center;
      }
    }
  }
}
#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }
  .custom-checkbox {
    display: flex;
    span {
      flex: 0 0 0.9375rem;
    }
    label {
      padding-left: 0.625rem;
    }
  }
}
#identity,
#authentication {
  .radio-inline {
    padding: 0;
    .custom-radio {
      margin-right: 0;
    }
  }
}
/*** Most of the customer accpunt pages ***/
.page-customer-account {
  #content {
    @include customer-area-box;
    .order-actions {
      a {
        padding: 0 0.125rem;
      }
    }
    .forgot-password {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: 1rem;
      padding-bottom: 0.9375rem;
    }
    .no-account {
      text-align: center;
      font-size: $base-font-size;
    }
  }
}
/*** Login page ***/
.page-authentication {
  #content {
    @include customer-area-base-box;
    max-width: 640px;
    margin: 0 auto;
  }
}
/*** Addresses page ***/
.page-addresses {
  .address {
   // @include box-shadow;
    background: white;
    margin-bottom: 1.875rem;
    font-size: $font-size-sm;
    color: $gray-darker;
    .address-body {
      padding: 1rem;
      h4 {
        font-size: $base-font-size;
        font-weight: 700;
      }
      address {
        min-height: 9rem;
      }
    }
    .address-footer {
      border-top: 1px solid $gray;
      padding: 0.5rem 1rem;
      a {
        color: $gray;
        margin-right: 0.5rem;
        &:hover {
          color: $gray-darker;
        }
        i {
          font-size: $base-font-size;
        }
        span {
          font-size: $font-size-lg;
          vertical-align: middle;
        }
      }
    }
  }
  .addresses-footer {
    margin: 0 0.9375rem;
    a {
      color: $gray-darker;
      &:hover {
        color: $gray-darker;
      }
      i {
        font-size: $base-font-size;
      }
      span {
        font-size: $base-font-size;
        vertical-align: middle;
        margin-top: $small-space;
      }
    }
  }
}
/*** Order details page ***/
.page-order-detail {
  font-size: 0.875rem;
  color: $gray;
  .box {
    @include customer-area-base-box;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: $base-font-size;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 1rem;
  }
  #order-infos {
    ul {
      margin: 0;
    }
  }
  #order-history {
    .history-lines {
      .history-line {
        padding: 0.5rem 0;
        border-bottom: 1px solid $gray-lighter;
        &:last-child {
          border-bottom: 0;
        }
        .label {
          display: inline-block;
          margin: 0.25rem 0;
          padding: 0.25rem 0.375rem;
          color: white;
          border-radius: 3px;
        }
      }
    }
  }
  .addresses {
    margin: 0 -0.9375rem;
    h4 {
      font-size: $base-font-size;
      font-weight: 700;
    }
  }
  #order-products {
    &.return {
      margin-bottom: 1rem;
      th {
        &.head-checkbox {
          width: 30px;
        }
      }
      td {
        padding: 1.375rem 0.75rem;
        &.qty {
          min-width: 125px;
          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }
          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;
            select {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .order-items {
    padding: 0 !important;
    .order-item {
      padding: 1rem 1rem 0;
      border-bottom: 1px solid $gray-lighter;
      .checkbox {
        width: 30px;
        float: left;
        padding: 0 0.9375rem;
      }
      .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 0.9375rem;
      }
      .desc {
        .name {
          font-weight: bold;
        }
        margin-bottom: 1rem;
      }
      .qty {
        margin-bottom: 1rem;
        .q {
          margin-bottom: 0.25rem;
        }
        .s {
          margin-bottom: 0.25rem;
        }
      }
    }
  }
  .messages {
    .message {
      margin-top: 0.5rem;
      border-bottom: 1px solid $gray-lighter;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        margin-bottom: 0.5rem;
      }
    }
  }
  .customization {
    margin-top: 0.75rem;
  }
}
/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }
  .customization {
    margin-top: 0.75rem;
  }
}
/*** My account landing page ***/
.page-my-account {
  #content {
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      a {
        text-align: center;
        display: inline-block;
        font-size: $base-font-size;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray;
        padding: 0 0.9375rem;
        margin-bottom: 1.875rem;
        span.link-item {
          display: block;
          height: 100%;
          @include customer-area-base-box;
        }
        i {
          display: block;
          font-size: 2.6rem;
          width: 100%;
          color: $gray-darker;
          padding-bottom: 3.4rem;
        }
        &:hover {
          color: $epi-color-blue;
          i {
            color: $epi-color-yellow;
          }
        }
      }
    }
  }
}
/*** History page ***/
#history {
  .orders {
    margin: 0 -1rem;
    .order {
      a {
        h3 {
          color: $gray;
        }
      }
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-lighter;
      .label {
        display: inline-block;
        margin: 0.25rem 0;
        padding: 0.25rem 0.375rem;
        color: white;
        border-radius: 3px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/*** FOOTER ***/
.page-footer {
  .account-link {
    margin-right: 1rem;
    i {
      font-size: $base-font-size;
    }
    span {
      font-size: $font-size-sm;
      vertical-align: middle;
    }
  }
}

.login-form {
  margin-top: 15px;
}
