@mixin search-box {
  form {
    position: relative;

    input[type=text] {
      &:focus {
        outline: 3px $brand-primary solid;
        color: $gray-darker;
        background: white;

        + button .search {
          color: $brand-primary;
        }
      }

      border: none;
      padding: 10px;
      min-width: 255px;
      color: $gray;
      border: $input-btn-border-width solid $input-border-color;
    }

    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.3125rem;
      right: 0.125rem;
      color: $gray;

      .search:hover {
        color: $brand-primary;
      }
    }
  }
}
/**
$themeColor : couleur du cercle
$colorTitle : couleur du text
$htemeBgColor : color du fond
**/
@mixin section-part-title($themeColor,$colorTitle,$themeBgColor) {
  $homeTitleZise: 80px;
  position: relative;
  color: $colorTitle;
  z-index: 2;
  text-align: center;
  padding: 5px;
  max-width: 300px;
  margin: 70px auto;
  font-family: "Oswald", sans-serif;
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;

  }
  &:before {
    height: $homeTitleZise;
    width: $homeTitleZise;
    bottom: 50%;
    box-shadow: 0 0 0 8px $themeColor;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    z-index: -2;
  }
  &:after {
    width: 100%;
    background: $themeBgColor;
    height: 100%;
    top: 1px;
    z-index: -1;
  }

}
@mixin middleCenter(){
  transform:translate3d(-50%,50%,0);
  left: 50%;
  bottom: 50%;
}


@mixin vertical-align(){
  transform :translateY(50%);
  bottom:50%;

}

@mixin horizontalCenter(){
  transform:translateX(-50%);
  left:50%;
}

@mixin box-shadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
