nav.header-nav {
  margin-bottom: 30px;

  #menu-icon {
    vertical-align: middle;
    cursor: pointer;
    margin: 0 1rem;

    .material-icons {
      line-height: 50px;
    }

  }


  .currency-selector {
    margin-top: 0.9375rem;
    margin-left: 0.9375rem;
    white-space: nowrap;
  }

  .language-selector {
    margin-top: 0.9375rem;
    white-space: nowrap;
  }

  .cart-preview {
    &.active {
      position: relative;

      a, i {
        //color: white;
      }
    }

    .shopping-cart {
      vertical-align: middle;
      color: $gray;
    }

    .body {
      display: none;
    }
  }

  #_desktop_contact_link {
    display: inline-block;

    #contact-link {
      margin-top: 0.9375rem;
    }
  }

  > .container {
    max-width: 1200px;
    width: 100%;
    padding: 20px 0;
    border-bottom: $gray-light 1px solid;
  }

  .right-nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

  }

  // boutton accueil
  .home-btn {
    margin-right: auto;
    margin-left: 150px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;

    a {
      display: block;
      height: 36px;
      width: 36px;
      overflow: hidden;
      text-indent: -9999px;
      background: url(../../img/home-icon.png) no-repeat;
    }
  }

  .wellcome-container {
    color: black;
    transition: ease-in-out .5s;
    padding: 10px 20px;
    margin-right: 50px;
  }

  .material-icons {
    line-height: inherit;

    &.expand-more {
      margin-left: -0.375rem;
    }
  }

  // barre de rechercher
  .search-widget {
    margin-top: 0.2rem;

    form input[type="text"] {
      border-radius: 30px;
      @include box-shadow;
      margin-left: auto;
      display: block;
      width: 180px;
      border: none;
      min-width: 200px !important;
      transition: ease-in-out .5s;

      &:focus {
        outline: none;
        width: 300px;
      }
    }
  }

  .user-info {
    white-space: nowrap;
    font-family: $font-oswald;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: 30px;

    .account {
      margin-left: $small-space;
    }
  }

  .blockcart {
    height: 3rem;
    padding: 0.75rem;
    text-align: center;
    white-space: nowrap;
    font-family: $font-oswald;
    font-size: 14px;
    text-transform: uppercase;

    i.shopping-cart {
      background: url(../../img/panier-icon.png) no-repeat;
      text-indent: -999px;
      overflow: hidden;
    }

    a {
      color: $gray;

      &:hover {
        color: $brand-primary;
      }
    }

    &.active {
      a:hover {
        color: white;
      }
    }

    .header {
      margin-top: 0.125rem;
    }
  }

  .user-info .material-icons {
    background: url(../../img/connexion.png) no-repeat;
    text-indent: -999px;
    overflow: hidden;
  }

}

//Le menu

.header-top {
  padding-bottom: 0;

  #_desktop_logo {
    max-width: 180px;
    position: absolute;
    text-align: center;
    top: -85px;
    left: -30px;

    > a {
      // position: absolute;
      //bottom: -20px;
      display: block;
      height: 100%;
      width: 100%;
      // left: -40px;
      &:hover h1 {
        color: black;
      }
    }

    h1 {
      font-size: 10px;
      text-transform: capitalize;
      text-align: center;
      margin-top: 10px;

      &:hover {
        color: black;
      }
    }
  }

  > .container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    border-bottom: 1px solid #dedede;

    > .row {
      display: flex;
    }
  }

  .menu {
    padding-left: 15px;
    margin-bottom: 0.375rem;
    font-family: $font-oswald;
  }

  .hook-menu {
    //position: static;
    float: none;
    width: calc(100% - 150px);
    margin-left: auto;
  }

  ul.top-menu {


    a[data-depth="0"] {
      color: black;
      text-transform: uppercase;
      max-width: 155px;
      font-size: 15px;
      white-space: normal;
      line-height: 1.1;
      transition: ease-in-out .3s;

      &:hover {
        color: $epi-color-blue !important;
      }
    }
  }

  .search-widget {
    float: right;
  }

  ul#top-menu {
    display: flex;
    justify-content: initial;
    flex-wrap: wrap;

    > li {
      @media screen and (min-width: 768px) {
        border-left: 1px solid #dedede;
        display: flex;
        align-items: center;
        position: relative;
      }
    }

    a[data-depth="1"] {
      padding: 5px 0;
      position: relative;

      &.dropdown-item > span {
        display: block !important;

        > span {
          display: none;
        }

        &:after {
          content: "+";
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          right: -5px;
          top: 0;
          text-align: center;
          font-size: 20px;
        }
      }

      &:hover {
        color: white;
      }
    }

    a[data-depth="2"] {
      text-transform: capitalize !important;

      &:hover {
        color: white;
      }
    }

    .sub-menu {
      top: 40px !important;

    }

    ul[data-depth="1"] > li {
      padding: 0 10px;

      ul {
        display: none;
      }

      &:hover {
        a[data-depth="1"] > span {
          &:after {
            content: "-";
          }
        }
        ul {
          display: block;

        }
      }
    }
  }

}

/* responsive */

@media screen and (min-width: 768px) {
  .sub-menu {
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 9px -1px;
  }

}


@media screen and (max-width: 767px) {
  nav.header-nav {
    background: transparent !important;

    .home-btn {
      display: none;
    }

    > .container {
      padding: 0;

      > .row {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .search-widget {
    width: calc(100% - 30px) !important;
    margin: 20px auto 25px auto !important;

    form input[type="text"] {
      width: 100% !important;
    }
  }
  nav.header-nav .blockcart .header {
    margin-top: -2px;
  }
  // mobile menu
  .mobile {
    display: flex;
    padding-top: 10px;
    background: white;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede;
    align-items: center;
    max-width: 100%;

    #_mobile_logo {
      order: 1;
      margin-right: auto;

      a {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        img {
          max-height: 50px;
          max-width: 100%;
        }

        h1 {
          font-size: 11px;
          padding: 0 8px;
          text-transform: capitalize;
        }
      }
    }

    #_mobile_user_info {
      order: 2;
    }

    #_mobile_cart {
      order: 3;
    }
  }

  .is-open {
    #_mobile_top_menu {
      border-bottom: none !important;
    }
  }
  .header-top {
    ul#top-menu {
      flex-direction: column;
      width: 100%;

      > li {
        border-left: none;

        a[data-depth="0"] {
          max-width: unset;
          border: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

}


@media screen and (min-width: 768px) and (max-width: 1130px) {
  nav.header-nav {
    .home-btn,
    .wellcome-container {
      display: none;
    }

  }
  .header-top {
    #_desktop_logo {
      position: absolute;
      top: -32px;
      left: 51px;
      max-width: 120px;
      width: 100%;

      img.logo {
        max-width: 100px;
      }

      > h1,
      > a {
        bottom: 0;
        left: -35px;
        margin-bottom: 10px;
      }

      + .col-md-10 {
        width: 100%;

        > div.menu {
          padding-left: 0;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }


      }
    }
  }
}

@media screen and (min-width: 1130px) and (max-width: 1340px) {
  .header-top #_desktop_logo {
    left: 0;

    img.logo {
      max-width: 130px;
    }

    > h1,
    > a {
      bottom: -35px;
      left: 10px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 992px) {
  .header-top {

    ul.top-menu {
      justify-content: center;

      a[data-depth="0"] {
        font-size: 12px;
        max-width: 128px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  #header .header-nav > .container {
    width: calc(100% - 30px);
  }
}